<template>
<div>
  <div style="height: 100vh"  v-if="$store.state.candidate.candidate.resumeFileUrl">
<!-- <b-button variant="outline-primary" class="btn-icon m-1" @click="print()">
      <feather-icon icon="PrinterIcon" size="14" />
      <small class=" ml-1" >print</small>
    </b-button>
      <b-button variant="outline-primary" class="btn-icon m-1" @click="dowmload(userData.resumeFileUrl)">
      <feather-icon icon="DownloadIcon" size="14" />
      <small class=" ml-1">Download</small>
    </b-button> -->

     <iframe  height="100%" width='100%' frameborder="0"  :title="$store.state.candidate.candidate.fullName" :src="pdfDataUrl+ '#view=fitH&toolbar=0'"   ></iframe>
    </div>
    <div v-if="!$store.state.candidate.candidate.resumeFileUrl">
    <h2 class="m-2" >No resume file found.</h2>
  </div>
  </div>
</template>

<script>
//import { jsPDF } from "jspdf";
import {
  BButton,
} from "bootstrap-vue";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";

export default {
  components: {
    BButton
  },
  
  // props: {
  //   userData: {
  //     type: Object,
  //     required: true,
  //   },
  //   pdfData: {
  //     type: Object,
  //     required: true,
  //   },
  // },
  // methods: {//const userData = ref(store.state.candidate?store.state.candidate.candidate:null );
	// 	dowmload (file) {
  //    //  var pdf = new jsPDF();
  //    // pdf.save(file)
  //    },
  //   print () { },
  
  computed: {
    pdfDataUrl() {
      const base64Data = this.$store.state.candidate.candidate.resumeFileUrl;
      if (!base64Data) return null;

      if (base64Data.startsWith('data:')) {
        return base64Data;
      }
            
      return `data:application/pdf;base64,${base64Data}`;
    }
  }
};
</script>

<style >
#sidenav-container {
    display: none !important;
}
</style>
